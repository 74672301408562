import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, EMPTY } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/home/shared/services/auth.service';

@Injectable({ providedIn: 'root' })
export class CallbackResolver  {
  constructor(private authService: AuthService, private router: Router) {}

  resolve(): Observable<void> {
    return this.authService.handleAuthCallback().pipe(
      concatMap(result => {
        if (result.loggedIn && !result.errorType) {
          this.router.navigate([result.targetUrl]);
          return EMPTY;
        } else if (!result.loggedIn && !result.errorType) {
          this.authService.login();
          return EMPTY;
        } else {
          this.authService.logout();
          return EMPTY;
        }
      }),
      take(1)
    );
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Loader } from '@googlemaps/js-api-loader';
import * as Sentry from '@sentry/angular';
import { SentryCategory } from '../models/sentry.model';

@Injectable({ providedIn: 'root' })
export class GoogleMapsLoaderService {
  load(): void {
    const loader = new Loader({
      apiKey: environment.googleMapsAPIKey,
      version: 'weekly'
    });

    loader
      .importLibrary('places')
      .then(() => {
        Sentry.addBreadcrumb({
          category: SentryCategory.googleMaps,
          message: 'Google Maps Loaded Successfully',
          type: 'info',
          level: 'info'
        });
      })
      .catch(err => {
        Sentry.captureException(err);
      });
  }
}
